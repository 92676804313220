<template>
  <div class="ud-body">
    <a-card :bordered="true" style="margin-bottom: 20px">
      <div class="title-wrap">
        <div class="title">
          <i class="iconfont icon-diqiu"></i>
          <span>产品分布</span>
        </div>
        <div class="form-wrap">
          <div>
            <span style="margin-right: 5px">所属运营商:</span>
            <a-select
              v-model:value="where.operator"
              placeholder="请选择所属运营商"
              style="width: 230px"
            >
              <a-select-option value="0">福建厦门三行网点</a-select-option>
            </a-select>
          </div>
          <a-button type="primary" style="margin-left: 10px">
            <i class="iconfont icon-zaitu" style="margin-right: 5px"></i>查询
          </a-button>
        </div>
      </div>
      <a-table
        style="margin-top: 20px"
        ref="table"
        row-key="province"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        @change="handleChange"
        :scroll="{ x: 'max-content' }"
        bordered
      >
        <template #customerId="{ record }">
          <div style="color: #409eff; text-decoration: underline">
            {{ record.customerId }}
          </div>
        </template>
        <template #customTitle>
          <div>
            所属运营商
            <i
              class="iconfont icon-bukejian"
              style="cursor: pointer"
              v-if="!isShowOperator"
              @click="isShowOperator = true"
            ></i>
            <i
              class="iconfont icon-kejian"
              style="cursor: pointer"
              v-else
              @click="isShowOperator = false"
            ></i>
          </div>
        </template>
        <template #operatorCustom="{ record }">
          <div v-if="!isShowOperator">
            {{
              record.operator.substring(0, 1) +
              new Array(record.operator.length).join('*')
            }}
          </div>
          <div v-else>
            {{ record.operator }}
          </div>
        </template>
      </a-table>
    </a-card>
    <a-card :bordered="true" style="margin-bottom: 20px">
      <div class="title-wrap">
        <div class="title">
          <i class="iconfont icon-shangxian"></i>
          <span>电池上线率</span>
        </div>
      </div>
      <div class="item-wrap">
        <div class="item">
          <span>44</span>
          <div>电池总数</div>
        </div>
        <div class="item">
          <span>90.91%</span>
          <div>上线率</div>
        </div>
        <div class="item">
          <span>40</span>
          <div>当日在线电池</div>
        </div>
        <div class="item">
          <span>0</span>
          <div>离线电池(1-7天)</div>
        </div>
        <div class="item">
          <span>4</span>
          <div>离线电池(7天以上)</div>
        </div>
      </div>
    </a-card>
    <a-card :bordered="true">
      <div class="title-wrap">
        <div class="title">
          <i class="iconfont icon-lixian-xian"></i>
          <span>离线7天以上电池信息</span>
        </div>
        <div>
          <a-button type="primary">
            <i class="iconfont icon-daochu" style="margin-right: 5px"></i>
            <span>导出</span>
          </a-button>
        </div>
      </div>
      <ud-pro-table
        style="margin-top: 20px"
        ref="table"
        row-key="province"
        :columns="batteryColumns"
        :datasource="batteryData"
        @change="handleChange"
        :scroll="{ x: 'max-content' }"
        bordered
      >
        <template #customerId="{ record }">
          <div style="color: #409eff; text-decoration: underline">
            {{ record.customerId }}
          </div>
        </template>
        <template #customTitle>
          <div>
            所属运营商
            <i
              class="iconfont icon-bukejian"
              style="cursor: pointer"
              v-if="!isShowOperator"
              @click="isShowOperator = true"
            ></i>
            <i
              class="iconfont icon-kejian"
              style="cursor: pointer"
              v-else
              @click="isShowOperator = false"
            ></i>
          </div>
        </template>
        <template #operatorCustom="{ record }">
          <div v-if="!isShowOperator">
            {{
              record.operator.substring(0, 1) +
              new Array(record.operator.length).join('*')
            }}
          </div>
          <div v-else>
            {{ record.operator }}
          </div>
        </template>
      </ud-pro-table>
    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      where: {},
      columns: [
        {
          title: '省份',
          // width: 130,
          align: 'center',
          dataIndex: 'province'
        },
        {
          title: '福建省',
          // width: 120,
          align: 'center',
          dataIndex: 'fj'
        },
        {
          title: '广东省',
          // width: 110,
          align: 'center',
          dataIndex: 'gd'
        },
        {
          title: '其他',
          // width: 190,
          align: 'center',
          dataIndex: 'qt'
        }
      ],
      data: [
        {
          province: '电池数量',
          fj: '2',
          gd: '5',
          qt: '3'
        }
      ],
      batteryColumns: [
        {
          title: '序号',
          width: 48,
          align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '电池编号',
          // width: 130,
          align: 'center',
          dataIndex: 'batteryCode'
        },
        {
          title: '设备编号',
          // width: 120,
          align: 'center',
          dataIndex: 'facilityCode'
        },
        {
          title: '业务状态',
          // width: 110,
          align: 'center',
          dataIndex: 'businessState '
        },
        {
          title: '离线天数',
          // width: 190,
          align: 'center',
          dataIndex: 'offLineDay'
        },
        {
          title: '数据时间',
          // width: 190,
          align: 'center',
          dataIndex: 'dataTime'
        },
        {
          title: '所属租赁点',
          // width: 190,
          align: 'center',
          dataIndex: 'point'
        }
      ],
      batteryData: [
        {
          batteryCode: 'BT938983429339583',
          facilityCode: 'SB932898829348',
          businessState: '站点库存',
          offLineDay: '75',
          dataTime: '2022-01-19 21:03:23',
          point: '明发2'
        },
        {
          batteryCode: 'BT938983429339553',
          facilityCode: 'SB932898829348',
          businessState: '站点库存',
          offLineDay: '25',
          dataTime: '2022-01-19 21:03:23',
          point: '明发2'
        },
        {
          batteryCode: 'BT938983429339573',
          facilityCode: 'SB932898829348',
          businessState: '站点库存',
          offLineDay: '35',
          dataTime: '2022-01-19 21:03:23',
          point: '明发2'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ud-table-tool) {
  display: none;
}
.item-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .item {
    color: #1890ff;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 32px;
      font-weight: bold;
    }
    div {
      font-size: 18px;
    }
  }
}
.title-wrap {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  .title {
    font-size: 32px;
    color: #1890ff;
    .iconfont {
      font-size: 32px;
      margin-right: 8px;
    }
  }
  .form-wrap {
    display: flex;
    align-items: center;
  }
}
</style>
